import { Injectable } from '@angular/core';
import { AppInitService, Initialable } from './app-init.service';
import { StorageService } from './storage.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { StudentService } from './student.service';
import { ToasterService } from '@commons/services';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/env';
import { catchError, firstValueFrom, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit'})
@EasyDebugDecorator
export class CdrVersionService {

  private cdrVersion: any;
  public displayMajModal: boolean = false;
  public cleanStorageDone: boolean = false;
  public displayRestartModal: boolean = false;
  public currentCdrVersion: boolean = false;

  constructor(
    private studentService: StudentService,
    private toasterService: ToasterService,
    private httpService: HttpClient,
    private storageService: StorageService,
    private appInitService: AppInitService,
    private authService: AuthService,
  ) {
  }

  async onInit() {
    console.log('Cdr Version init');
    const student = this.studentService.student;
    this.cdrVersion = this.studentService.student.userCdrVersion;
    this.currentCdrVersion = this.studentService.student.currentCdrVersion;
    if (!!student && student.remoteId !== 'GUEST') {
      if (!this.isMajRequired()) {
        if (!this.isStorageCleaned()) {
          this.displayRestartModal = true;
          console.log('restart required');
        }
        this.displayMajModal = false;
        return 'Cdr Version done';
      } else {
        // MAJ MODAL OU MAJ MODAL SKIP POUR DRIVING TRAINING
        console.log('maj required');
        this.displayMajModal = true;
        return 'Cdr Version done';
      }
    }
  }

  isMajRequired () {
    console.log('cdr version => ', this.cdrVersion);
    console.log('current cdr version => ', this.currentCdrVersion);
    console.log('is version equals => ', this.cdrVersion === this.currentCdrVersion);
    if (this.cdrVersion === this.currentCdrVersion) {
      return false;
    } else {
      return true;
    }
  }

  async handleMajCode2023() : Promise<any> {
    const resClean = await this.callCleanSessions();
    console.log(resClean);
    if (!resClean) {
      return false;
    }
    const isStoragedCleaned = await this.isStorageCleaned();
    if (!isStoragedCleaned) {
      await this.clearStorageData();
    }
    this.launchToaster();
    this.displayMajModal = false;
    return true;
  }

  launchToaster() {
    const toasterConfig = {
      text: `L’application est bien à jour, tu peux poursuivre tes séries !`,
      bgcolor: 'var(--color-success)',
      duration: '5000'
    };
    this.toasterService.create(toasterConfig);
  }

  async launchMajCode2023() {
    console.log('launch Maj Code 2023');
    return;
  }

  async clearStorageData() {
    const jwtToken = await this.storageService.get('jwtToken');
    const student = this.studentService.student;
    await this.storageService.clear();
    if (!!jwtToken) {
      const test = await this.storageService.set('jwtToken', jwtToken);
    }
    const res = await this.authService.fetchProfile(student.remoteId, 'students');
    if (!!res && !res.errorCode) {
      await this.studentService.fillAccount(res, student.remoteId);
      await this.appInitService.init2();
      this.storageService.set('storageCleanedAfterMajCode2023', true);
    }
  }

  async isStorageCleaned() {
    const isStoragedCleaned = !!(await this.storageService.get('storageCleanedAfterMajCode2023')) ? true : false;
    console.log('storage cleaned');
    return isStoragedCleaned;
  }

  async callCleanSessions(): Promise<any> {
    console.log('call clean sessions');
    const url = `${environment.cdrBase}/v3/cdr/user_version`;

    return firstValueFrom(this.httpService.put(url, {}).pipe(
      switchMap(
        async res => {
          console.log('call clean sessions success => ', res);
          return true;
        }
      ),
      catchError(
        async err => {
          console.log('call clean sessions error => ', err);
          return false;
        }
      )
    ));
  }

  async majSuccess() {
    console.log('maj success');
    return;
  }

  async reloadApp() {
    console.log('reload app');
    return;
  }
}
