import { SentryService, KameleoonService, WonderPushService, SiteOpener, AuthService, TokenService } from '@app/app.services';
import { FeatureFlagsService } from './services/feature-flags.service';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { GtmService } from './services/gtm.service';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
// ANGULAR
import { APP_INITIALIZER, NgModule, Injector, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule, RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// SENTRY
import * as Sentry from '@sentry/angular-ivy';

// IONIC
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

// APP
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.errorHandler';
// MODALS
import {
  DisconectedModalComponent,
  ModalMarketingComponent,
  ModalIframeComponent,
  ModalErrorComponent,
} from './app.modals';

// SERVICES
import {
  ServiceLocator,
  PageLoaderService,
  EasyDebugService,
  StorageService,
  PermissionsService,
  AppInitService,
  StudentService,
  CameraService,
  LoggerService,
  NetworkStatusService,
  MigrationService,
  UserSessionService,
  SyncCDRService,
  ConfigService,
  StatsService,
  BrowserUpdateService,
  AssetsService,
  UserSessionInterceptor,
  PaymentsService,
  GiftsService,
  CerfaService,
  ConfigUserService,
  NavigationService,
  CdrVersionService,
  SessionsService,
  QuestionsService,
  SeriesService,
  OffersService,
  BookletService,
  LessonsService,
  ExamenCodeService,
  ExamenConduiteService,
  AnswersService,
  CouponsService,
  AppRatingService,
  CDRLessonsService,
  PointsDeRendezVousService,
  DevDebugModeService,
  GamificationService,
  StudentDocumentsService,
  MailcheckerService,
  CPFSubscriptionsService,
  TunnelPaiementService,
  MapTeacherService,
  ReserverLeconService,
} from './app.services';

import { UserErrorHandlerService } from '../app/services/user-error-handler.service';

import { environment } from '../environments/environment';
import { EvsMicroService } from '@commons/services/EvsMicroService.service';
// import { EvsMicroServiceInterceptor } from '@commons/services/EvsMicroService.interceptor';
import { EvsApiHeaderService } from '../commons/services/EvsApiHeader.service';
import { EvsApiHeaderInterceptor } from '@commons/services/EvsApiHeader.interceptor';
import { EvsResponseInterceptor } from '@commons/services/EvsResponse.interceptor';

// ROUTING
import { AppRoutingModule } from './app-routing.module';

// DIRECTIVES
import { DirectivesModule } from '@commons/directives';
import { CommonsComponentsModule } from '@commons/components';

// ZENDESK
import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';
import { Adjust } from '@awesome-cordova-plugins/adjust/ngx';
import { AdjustService } from './services/adjust.service';
import { FirebaseService } from './services/firebase.service';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { SoftDeployService } from './services/softDeploy.service';
import { FormsModule } from '@angular/forms';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { ErrorCatcherService } from './services/errorCatcher.service';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';

class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  timeOut = 30000;
  accountUrl = environment.env === 'production' ? 'envoituresimone.zendesk.com' : 'envoituresimone1690361742.zendesk.com';
  callback(zE) {
    zE('webWidget', 'setLocale', 'fr');
    zE('webWidget', 'hide');
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DisconectedModalComponent,
    ModalMarketingComponent,
    ModalIframeComponent,
    ModalErrorComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    RouterModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    CommonsComponentsModule,
    DirectivesModule,
    FormsModule,
    SocialLoginModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    EvsApiHeaderService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: EvsApiHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EvsResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserSessionInterceptor, multi: true },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    InAppBrowser,
    AppInitService,
    PageLoaderService,
    EasyDebugService,
    DevDebugModeService,
    PermissionsService,
    StudentService,
    GoogleTagManagerService,
    Facebook,
    GooglePlus,
    StorageService,
    LoggerService,
    NetworkStatusService,
    MigrationService,
    StatsService,
    NgxZendeskWebwidgetService,
    UserSessionService,
    SyncCDRService,
    ConfigService,
    Network,
    Platform,
    Camera,
    CameraService,
    AssetsService,
    FileTransfer,
    File,
    Device,
    BrowserUpdateService,
    EvsMicroService,
    CerfaService,
    PaymentsService,
    GiftsService,
    CouponsService,
    OffersService,
    ConfigUserService,
    SessionsService,
    QuestionsService,
    SeriesService,
    NavigationService,
    LessonsService,
    BookletService,
    ExamenCodeService,
    ExamenConduiteService,
    // { provide: HTTP_INTERCEPTORS, useClass: EvsMicroServiceInterceptor, multi: true },
    AnswersService,
    AppRatingService,
    Adjust,
    CDRLessonsService,
    AdjustService,
    GtmService,
    FirebaseService,
    // FirebaseX,
    Deploy,
    SoftDeployService,
    PointsDeRendezVousService,
    GamificationService,
    Deeplinks,
    Market,
    SentryService,
    FeatureFlagsService,
    KameleoonService,
    StudentDocumentsService,
    ErrorCatcherService,
    UserErrorHandlerService,
    WonderPush,
    WonderPushService,
    LaunchReview,
    MailcheckerService,
    CPFSubscriptionsService,
    TunnelPaiementService,
    MapTeacherService,
    ReserverLeconService,
    SignInWithApple,
    SiteOpener,
    AuthService,
    TokenService,
    CdrVersionService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('24457391814-th3e0qebifu5dirh3d18iladk5qpvfv0.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('843120129082701')
          }
        ]
      } as SocialAuthServiceConfig
    },
    SocialAuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
