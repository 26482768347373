import { EditEmailPage } from './../../pages/edit-email/edit-email.page';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Initialable } from './app-init.service';
import { environment } from './../../environments/environment';
import { StudentService } from './student.service';
import { Platform } from '@ionic/angular';
import { FirebaseService } from './firebase.service';
import { GtmService } from './gtm.service';
import { AdjustService } from './adjust.service';
import { Serie } from '../models/serie';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { NetworkStatusService } from './network-status.service';
import { AuthService } from './auth.service';

export interface Event {
  name: string;
  payload?: any;
}

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init4', initializer: `onInit` })
@EasyDebugDecorator
export class StatsService {

  eventsObs = new Subject<Event>();
  firebaseSubscription = null;
  gtmSubscription;
  adjustSubscription;
  isDebug = false;

  constructor(
    private studentService: StudentService,
    private platformService: Platform,
    private firebaseService: FirebaseService,
    private gtmService: GtmService,
    private adjustService: AdjustService,
    private networkStatusService: NetworkStatusService,
    private authService: AuthService
  ) { }

  async onInit() {
    this.initFirebaseAnalytic();
    await this.initGTM();
    this.initAdjust();
    return 'Stats done';
  }

  async processUser(): Promise<any> {
    const isSignedIn = await this.studentService.isSignedInSoft();
    // console.log('isSignedIn => ', isSignedIn);
    if (!!this.studentService.student && this.studentService.student.status !== 'guest' && !!isSignedIn) {
      // console.log('processed user');
      const userProperties = await this.studentService.fetchUserProperties();

      if (!!userProperties) {
        let user_environement: string;
        if (this.platformService.is('cordova')) {
          if (this.platformService.is('ios')) {
            user_environement = 'ios';
          } else if (this.platformService.is('android')) {
            user_environement = 'android';
          }
        } else {
          user_environement = 'web_app';
        }

        const userData = {
          client_type: (!!userProperties.client_type) ? userProperties.client_type : null,
          wizbii_user: (!!userProperties.wizbii_user) ? userProperties.wizbii_user : null,
          use_criteo: (!!userProperties.use_criteo) ? userProperties.use_criteo : null,
          user_created_at: (!!userProperties.user_created_at) ? userProperties.user_created_at : null,
          user_id: (!!userProperties.user_id) ? userProperties.user_id : null,
          user_status: (!!userProperties.status) ? userProperties.status : null,
          user_first_purchase: (!!userProperties.user_first_purchase) ? userProperties.user_first_purchase : null,
          registered_zipcode: (!!userProperties.registered_zipcode) ? userProperties.registered_zipcode : null,
          user_said_in_ae: (!!userProperties.declared_registered_in_driving_school) ? userProperties.declared_registered_in_driving_school : null,
          has_cdr_success: (!!userProperties.has_cdr_success) ? userProperties.has_cdr_success : null,
          has_active_cpf: (!!userProperties.has_active_cpf) ? userProperties.has_active_cpf : null,
          user_series_started: (!!userProperties.user_series_session_started) ? userProperties.user_series_session_started : null,
          user_series_completed: (!!userProperties.user_series_session_completed) ? userProperties.user_series_session_completed : null,
          user_signup_type: (!!userProperties.signed_up_from) ? userProperties.signed_up_from : null,
          user_environement: (!!user_environement) ? user_environement : null,
          driving_department: (!!userProperties.driving_department && !!userProperties.driving_department.pricing_zone_id && !!userProperties.driving_department.department) ? userProperties.driving_department.department : null,
          pricing_zone: (!!userProperties.pricing_zone) ? userProperties.pricing_zone : null,
          hashed_email: (!!userProperties.email) ? userProperties.email : null,
          hashed_phone: (!!userProperties.phone) ? userProperties.phone : (!!userProperties.hashed_phone) ? userProperties.hashed_phone : null,
          hashed_phone_tiktok: (!!userProperties.hashed_phone_tiktok) ? userProperties.hashed_phone_tiktok : null,
        };
        return userData;
      }
    }
    return {user_status: 'guest'};
  }

  processEnv(): any {
    const envData = {
      evs_access: 'app',
      evs_os: 'web',
      evs_platform: 'app',
      evs_service: 'spa',
      ionic_platform: this.platformService.is('android') ? 'android' : this.platformService.is('ios') ? 'ios' : 'unknown',
      spa_version: environment.version,
      site_type: 'm',
      evs_env: environment.env === 'production' ? 'prod' : 'staging',
    };

    return envData;
  }

  processSerie(serie: Serie): any {
    const serieData = {
      serie_type: serie.type,
      serie_number: serie.position + 1
    };

    return serieData;
  }

  send(event: Event) {
    // console.log('send event => ', event);
    this.eventsObs.next(event);
  }

  processItem(banner): any {
    const itemData: any = {
      item_id: banner.item_id,
      item_promo_type: banner.item_promo_type,
      item_location_id: banner.item_location_id,
      item_location_format: banner.item_location_format,
      item_promo_content: banner.item_promo_content,
    };
    if (!!banner.cdr_question_id) {
      itemData.cdr_question_id = banner.cdr_question_id;
    }
    // console.log('item data => ', itemData);
    return itemData;
  }

  initFirebaseAnalytic() {
    if (this.platformService.is('cordova')) {
      if (!this.studentService.isGuest()) {
        // this.firebaseService.firebase.setUserId(this.studentService.student.remoteId);
      }

      if (!!this.firebaseSubscription) {
        this.firebaseSubscription.unsubscribe();
      }

      this.firebaseSubscription = this.eventsObs.subscribe(async event => {
        // console.log('event => ', event);
        if (!!event) {
          if (!this.networkStatusService?.isOffline()) {
            const user = await this.processUser();
            delete user.user_id;
            const env = this.processEnv();
            // console.log('switch firebase', event.name, event.payload);
            switch (event.name) {
              case 'user:register': { this.firebaseService.sendFirebaseEvent('sign_up', user, env, {...event.payload}); break; }
              case 'user:login': { this.firebaseService.sendFirebaseEvent('login', user, env, null); break; }
              case 'user:logout': { this.firebaseService.sendFirebaseEvent('logout', user, env, null); break; }
              case 'page:view': { this.firebaseService.sendFirebaseEvent('Pageview', user, env, {pageName: event.payload.page, loadTime: event.payload.loadTime, products_info: event.payload?.products_info}); break; }
              case 'page:redirect': { this.firebaseService.sendFirebaseEvent('redirect', user, env, {page_redirect: event.payload.pageRedirect}); break; }
              case 'modal:view': { this.firebaseService.sendFirebaseEvent('modal_view', user, env, {pageName: event.payload.page, referer: event.payload.referer}); break; }
              case 'modal:unview': { this.firebaseService.sendFirebaseEvent('modal_unview', user, env, {pageName: event.payload.page}); break; }
              case 'session:started': { this.firebaseService.sendFirebaseEvent('serie_session_started', user, env, this.processSerie(event.payload.serie)); break; }
              case 'session:completed': { this.firebaseService.sendFirebaseEvent('serie_session_completed', user, env, this.processSerie(event.payload.serie)); break; }
              case 'item:viewed': { this.firebaseService.sendFirebaseEvent('view_item', user, env, this.processItem(event.payload.item)); break; }
              case 'item:clicked': { this.firebaseService.sendFirebaseEvent('clicked_item', user, env, this.processItem(event.payload.item)); break; }
              case 'button:click': { this.firebaseService.sendFirebaseEvent('button_click', user, env, {category: event.payload.category, label: event.payload.label}); break; }
              case 'init:sync_cdr': { this.firebaseService.sendFirebaseEvent('synchro_offline', user, env, {nbSeriesToSynchro: event.payload.nbSeriesToSynchro, nbSeriesSynchronized: event.payload.nbSeriesSynchronized, loadTime: event.payload.loadTime}); break; }
              case 'modal:display_cpf': { this.firebaseService.sendFirebaseEvent('cpf_subscription_display', user, env, {...event.payload}); break; }
              case 'modal:start_cpf': { this.firebaseService.sendFirebaseEvent('cpf_subscription_starts', user, env, {...event.payload}); break; }
              case 'user:code_exam_booked': { this.firebaseService.sendFirebaseEvent('code_exam_booked', user, env, null); break; }
              case 'user:ios_consent' : { this.firebaseService.sendFirebaseEvent('ios_consent', user, env, {...event.payload}); break; }
              case 'page:tunnel': { this.firebaseService.sendFirebaseEvent('add_to_cart', user, env, {...event.payload}); break; }
              case null: { break; }
              default: { break; }
            }
          }
        }
      });
    }
  }

  async initGTM() {
    this.gtmService.gtmService.addGtmToDom();

    if (!!this.gtmSubscription) {
      this.gtmSubscription.unsubscribe();
    }

    this.gtmSubscription = this.gtmSubscription = this.eventsObs.subscribe(async event => {
      // console.log('event => ', event);
      if (!!event) {
        if (!this.networkStatusService?.isOffline()) {
          const user = await this.processUser();
          const env = this.processEnv();
          // console.log('switch gtm', event.name, event.payload);
          switch (event.name) {
            case 'user:register': { this.gtmService.sendGtmEvent('sign_up', user, env, {sign_up_geo: event.payload.isSignUpGeo, sign_up_optin: event.payload.sign_up_optin}); break; }
            case 'user:login': { this.gtmService.sendGtmEvent('login', user, env, null); break; }
            case 'user:logout': { this.gtmService.sendGtmEvent('logout', user, env, null); break; }
            case 'page:view': { this.gtmService.sendGtmEvent('Pageview', user, env, {pageName: event.payload.page, loadTime: event.payload.loadTime, products_info: event.payload?.products_info}); break; }
            case 'page:redirect': { this.gtmService.sendGtmEvent('redirect', user, env, {page_redirect: event.payload.pageRedirect}); break; }
            case 'modal:view': { this.gtmService.sendGtmEvent('modal_view', user, env, {pageName: event.payload.page, referer: event.payload.referer}); break; }
            case 'modal:unview': { this.gtmService.sendGtmEvent('modal_unview', user, env, {pageName: event.payload.page}); break; }
            case 'session:started': { this.gtmService.sendGtmEvent('serie_session_started', user, env, this.processSerie(event.payload.serie)); break; }
            case 'session:completed': { this.gtmService.sendGtmEvent('serie_session_completed', user, env, this.processSerie(event.payload.serie)); break; }
            case 'item:viewed': { this.gtmService.sendGtmEvent('view_item', user, env, this.processItem(event.payload.item)); break; }
            case 'item:clicked': { this.gtmService.sendGtmEvent('clicked_item', user, env, this.processItem(event.payload.item)); break; }
            case 'button:click': { this.gtmService.sendGtmEvent('button_click', user, env, {category: event.payload.category, label: event.payload.label}); break; }
            case 'init:sync_cdr': { this.gtmService.sendGtmEvent('synchro_offline', user, env, {nbSeriesToSynchro: event.payload.nbSeriesToSynchro, nbSeriesSynchronized: event.payload.nbSeriesSynchronized, loadTime: event.payload.loadTime}); break; }
            case 'modal:display_cpf': { this.gtmService.sendGtmEvent('cpf_subscription_display', user, env, {...event.payload}); break; }
            case 'modal:start_cpf': { this.gtmService.sendGtmEvent('cpf_subscription_starts', user, env, {...event.payload}); break; }
            case 'user:code_exam_booked': { this.gtmService.sendGtmEvent('code_exam_booked', user, env, null); break; }
            case 'user:ios_consent' : { this.gtmService.sendGtmEvent('ios_consent', user, env, {...event.payload}); break; }
            case 'page:tunnel': { this.gtmService.sendGtmEvent('add_to_cart', user, env, {...event.payload}); break; }
            case 'lessons:map_dispo_alert': { this.gtmService.sendGtmEvent('map_dispo_alert', user, env, {...event.payload}); break; }
            case null: { break; }
            default: { break; }
          }
        }
      }
    });
  }

  initAdjust() {
    if (this.platformService.is('cordova')) {

      if (!!this.adjustSubscription) {
        this.adjustSubscription.unsubscribe();
      }

      this.adjustSubscription = this.eventsObs.subscribe(async event => {
        if(!!event) {
          if (!this.networkStatusService?.isOffline()) {
            const user = await this.processUser();
            const env = this.processEnv();
            // console.log('switch adjust', event.name, event.payload);
            switch (event.name) {
              case 'user:login': { this.adjustService.sendAdjustEvent('40ycb9', user, env, null); break; }
              case 'add_to_cart:big_pack': { this.adjustService.sendAdjustEvent('yv6obm', user, env, null); break; }
              case 'initiate_checkout:big_pack': { this.adjustService.sendAdjustEvent('a7tpi7', user, env, null); break; }
              case 'initiate_checkout:hours': { this.adjustService.sendAdjustEvent('987wr5', user, env, null); break; }
              case 'modal:display_cpf': { this.adjustService.sendAdjustEvent('s517y6', user, env, null); break; }
              case 'modal:start_cpf': { this.adjustService.sendAdjustEvent('c5ml7l', user, env, null); break; }
              case 'user:code_exam_booked': { this.adjustService.sendAdjustEvent('6i8k66', user, env, null); break; }
              case null: { break; }
              default: { break; }
            }
          }
        }
      });
    }
  }
}
